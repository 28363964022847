import { Stack, styled, useTheme } from '@formbio/ui/components';
import { Tooltip } from '@formbio/ui/components/Tooltip';
import StatusChip from '@formbio/ui/components/Chip/StatusChip';
import {
  Info as InfoIcon,
  Stop as StopIcon,
} from '@formbio/ui/components/Icons';
import {
  CheckCircle as CheckCircleIcon,
  WarningCircle as WarningCircleIcon,
  Question as QuestionIcon,
  ClockCounterClockwise as ClockCounterClockwiseIcon,
  Warning as WarningIcon,
} from '@formbio/ui/components/Icons';
import { RunStatusValues } from '@formbio/ui/types';

export const StyledTooltip = styled(Tooltip)(({ theme }) => ({
  display: 'flex',
  '.icon': {
    width: theme.spacing(2),
    color: theme.palette.warning.main,
  },
}));

export const StyledStack = styled(Stack)(() => ({
  alignItems: 'center',
}));

/**
 * Some states (Completed with warning) require an extra icon
 * For we only have the warning icons
 * but we could add more here
 */
function ExtraIcon({ type }: { type: string }) {
  switch (type) {
    case RunStatusValues.COMPLETED_WARNING:
      return (
        <StyledTooltip title='Check the Logs'>
          <span>
            <InfoIcon className='icon' />
          </span>
        </StyledTooltip>
      );
    default:
      return null;
  }
}

function getStatusIcon(type: string) {
  switch (type) {
    case RunStatusValues.RUNNING:
    case RunStatusValues.PENDING:
      return <ClockCounterClockwiseIcon />;
    case RunStatusValues.COMPLETED:
    case RunStatusValues.COMPLETED_WARNING:
      return <CheckCircleIcon />;
    case RunStatusValues.FAILED:
      return <WarningCircleIcon />;
    case RunStatusValues.CANCELED:
      return <WarningIcon />;
    case RunStatusValues.REMOVED:
      return <StopIcon />;
    default:
      return <QuestionIcon />;
  }
}

function useColors(type: string) {
  const theme = useTheme();
  switch (type) {
    case RunStatusValues.RUNNING:
    case RunStatusValues.PENDING:
      return {
        backgroundColor: theme.palette.info[200],
        contrastColor: theme.palette.info[600],
      };
    case RunStatusValues.COMPLETED:
    case RunStatusValues.COMPLETED_WARNING:
      return {
        backgroundColor: theme.palette.success[200],
        contrastColor: theme.palette.success[600],
      };
    case RunStatusValues.FAILED:
      return {
        backgroundColor: theme.palette.error[200],
        contrastColor: theme.palette.error[600],
      };
    case RunStatusValues.CANCELED:
      return {
        backgroundColor: theme.palette.warning[200],
        contrastColor: theme.palette.warning[600],
      };
    default:
      return {
        backgroundColor: theme.palette.neutral[200],
        contrastColor: theme.palette.neutral[600],
      };
  }
}

export default function RunStatusChip({
  type,
  clickable = false,
}: {
  type: string;
  clickable?: boolean;
}) {
  const typeLowerCase = type.toLowerCase();
  const colors = useColors(typeLowerCase);
  const labelText =
    typeLowerCase === RunStatusValues.COMPLETED_WARNING
      ? RunStatusValues.COMPLETED.toLowerCase()
      : typeLowerCase;
  return (
    <StyledStack direction='row' spacing={1}>
      <StatusChip
        icon={getStatusIcon(typeLowerCase)}
        clickable={clickable}
        colors={colors}
        labelText={labelText}
      />
      <ExtraIcon type={typeLowerCase} />
    </StyledStack>
  );
}
