// The flag id referenced in Launch Darkly
export const featureFlagIds = {
  dryRunWorkflow: 'dryRunWorkflow',
  mockWorkflowRun: 'mockWorkflowRun',
  disableAccountCreation: 'disableAccountCreation',
  disableCreateAccountPage: 'disableCreateAccountPage',
  enableEasterEgg: 'enableEasterEgg',
  enableRichNotes: 'enableRichNotes',
  showLauncherFieldId: 'showLauncherFieldId',
  enableRstudio: 'enableRstudio',
  enableCopyForCli: 'enableCopyForCli',
  enableNewUserInvites: 'enableNewUserInvites',
  enableGithubApp: 'enableGithubApp',
  mockDataHandlers: 'mockDataHandlers',
  enableRunFromGithubCommand: 'enableRunFromGithubCommand',
  enableMaintenancePage: 'enableMaintenancePage',
  enableMaintenanceTimeWindowAlert: 'enableMaintenanceTimeWindowAlert',
  enabledFormBenchApps: 'enabledFormBenchApps',
  enableWfLaunchSdcSearch: 'enableWfLaunchSdcSearch',
  enableProvenanceUi: 'enableProvenanceUi',
  enableWfRunMetadataPanel: 'enableWfRunMetadataPanel',
  enableWorkflowVisualizations: 'enableWorkflowVisualizations',
  filetypePreviewSupport: 'filetypePreviewSupport',
  enableByoFormBench: 'enableByoFormBench',
  enableValidators: 'enableValidators',
  enableBillingErrorBanner: 'enableBillingErrorBanner',
  enableMultipleGpus: 'enableMultipleGpus',
  enableWfRunOutputFiles: 'enableWfRunOutputFiles',
  enableSearchBar: 'enableSearchBar',
  sessionIdleTimeouts: 'sessionIdleTimeouts',
  formOnlyWorkflows: 'formOnlyWorkflows',
  enableGuideMenu: 'enableGuideMenu',
  fileUploadBatchDelay: 'fileUploadBatchDelay',
  enableBlog: 'enableBlog',
  enableSignInWithAccountId: 'enableSignInWithAccountId',
  basicWorkflows: 'basicWorkflows',
  enableGpu: 'enableGpu',
  enableLabels: 'enableLabels',
  useProcessExecutionsFromFolder: 'useProcessExecutionsFromFolder',
  hideNetworkErrors: 'hideNetworkErrors',
  enableTabularEditor: 'enableTabularEditor',
  enableVectorFeatureEdit: 'enableVectorFeatureEdit',
  synthAssayLimit: 'synthAssayLimit',
  enableSequenceInput: 'enableSequenceInput',
  vectorJsonFormats: 'vectorJsonFormats',
  enableCaas: 'enableCaas',
  showVectorflowExperience: 'showVectorflowExperience',
  caasWorkflowVersion: 'caasWorkflowVersion',
  enableDataEntityManager: 'enableDataEntityManager',
  enableGenericDataEntityManager: 'enableGenericDataEntityManager',
  caasLaavaWorkflowVersion: 'caasLaavaVersion',
  enableDefaultNextflowVersion21_04_3: 'enableDefaultNextflowVersion21_04_3',
  enableLaavaForCaas: 'enableLaavaForCaas',
  enableFisaInVectorTables: 'runFisaFromVectorTables',
  runCaasOnExistingVectors: 'runCaasOnExistingVectors',
  enableHeatmapColorChoice: 'enableHeatmapColorChoice',
  enableCreateVectorFromVault: 'enableCreateVectorFromVault',
  enableEntityDeletion: 'enableEntityDeletion',
  enableOverrideBarChartConfig: 'enableOverrideBarChartConfig',
  enableVectorGeneration: 'enableVectorGeneration',
  enableIndicationConfig: 'enableIndicationConfig',
  allowVectorExport: 'allowVectorExport',
  enableNewVfFlow: 'enableNewVfFlow',
  showUnimplementedIndicationPageFeatures:
    'showUnimplementedIndicationPageFeatures',
  useNewHeatmapResponse: 'useNewHeatmapResponse',
};

const config = {
  stage: process.env.NEXT_PUBLIC_STAGE,
  apiUrl: process.env.NEXT_PUBLIC_API_URL,
  firebaseApiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  firebaseAuthDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  firebaseProjectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  firebaseStorageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  firebaseMessagingSenderId:
    process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  firebaseAppId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  firebaseMeasurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
  sentryDSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
  sentryENV: process.env.NEXT_PUBLIC_SENTRY_ENV,
  isLocal: process.env.NEXT_PUBLIC_STAGE?.includes('local'),
  banthaApiVersion: process.env.NEXT_PUBLIC_BANTHA_API_VERSION,
  launchDarklyClientId: process.env.NEXT_PUBLIC_LAUNCHDARKLY_SDK_CLIENT,
  segmentAnalyticsApiKey: process.env.NEXT_PUBLIC_SEGMENT_ANALYTICS_WRITE_KEY,
  handsontableLicense: process.env.NEXT_PUBLIC_HANDSONTABLE_LICENSE,
  rshinyUrl: process.env.NEXT_PUBLIC_RSHINY_URL,
  formBenchUrl: process.env.NEXT_PUBLIC_FORMBENCH_URL,
  githubAppClientId: process.env.NEXT_PUBLIC_GHAPP_CLIENT_ID,
  githubAppName: process.env.NEXT_PUBLIC_GHAPP_NAME,
  supersetUrl: process.env.NEXT_PUBLIC_SUPERSET_URL,
  gcpMarketPlaceUrl: process.env.NEXT_PUBLIC_GCP_MARKETPLACE_URL || '',
  blogUrl: process.env.NEXT_PUBLIC_BLOG_URL,
  platformUrl: process.env.NEXT_PUBLIC_PLATFORM_URL,
};

export default config;
