import type {} from '@mui/lab/themeAugmentation'; // this import allows the components section to recognize "lab" components until it gets baked in in v5
import {
  createTheme,
  responsiveFontSizes,
  Shadows,
  Theme,
} from '@mui/material/styles';
import type {} from '@mui/x-data-grid/themeAugmentation'; // this import allows the components section to recognize MuiDataGrid components
import { componentsDark, default as componentsFormbio } from './components';
import { default as paletteFormbio, paletteDark } from './palette';
import {
  default as typographyFormbio,
  typographyDark,
  FontFamilies,
  defaultFontFamilies,
} from './typography';

import { ExtendedPalette, ExtendedTypography } from './type-extension';
import { info } from './palette/info';
import { SCROLLBAR_SIZE, SCROLLBAR_RADIUS } from './constants';

declare module '@mui/material/styles' {
  // this actually adds custom overrides to the Theme interface
  interface Theme {
    palette: ExtendedPalette;
    typography: ExtendedTypography;
  }
}

type FormbioTheme = (fontFamilies?: FontFamilies) => Theme;

/**
 * Adds the info shadow as shadows[20] so that it can be used in the theme
 * @param themeShadows the default shadows from the theme
 */
function customizeLastShadow(themeShadows: Shadows) {
  return [
    ...themeShadows.slice(0, 20),
    `0px 0px 0px 4px ${info[300]}`,
    ...themeShadows.slice(21),
  ] as Shadows;
}

export const themeFormbio: FormbioTheme = (
  fontFamilies = defaultFontFamilies,
) => {
  const theme = createTheme({
    components: {
      ...componentsFormbio,
      MuiCssBaseline: {
        styleOverrides: themeParam => ({
          '&::-webkit-scrollbar': {
            width: SCROLLBAR_SIZE,
            height: SCROLLBAR_SIZE,
            minWidth: '0.5vmax',
            minHeight: '0.5vmax',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: themeParam.palette.primary[400],
            outline: themeParam.palette.primary[400],
            borderRadius: SCROLLBAR_RADIUS,
            border: `2px solid transparent`,
            backgroundClip: 'content-box',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            border: `1px solid transparent`,
          },
          // for components with dark backgrounds in the light theme
          '.dark-scroll': {
            '&::-webkit-scrollbar-thumb ': {
              backgroundColor: themeParam.palette.primary[700],
              outline: themeParam.palette.primary[700],
            },
          },
        }),
      },
    },
    typography: typographyFormbio(fontFamilies),
    palette: paletteFormbio,
  });
  theme.shadows = customizeLastShadow(theme.shadows);
  return responsiveFontSizes(theme);
};

export const themeFormbioDark: FormbioTheme = (
  fontFamilies = defaultFontFamilies,
) => {
  const theme = createTheme({
    // fallback to regular light formbio components
    components: {
      ...componentsFormbio,
      ...componentsDark,
      MuiCssBaseline: {
        styleOverrides: themeParam => ({
          '&::-webkit-scrollbar': {
            width: SCROLLBAR_SIZE,
            height: SCROLLBAR_SIZE,
            minWidth: '0.5vmax',
            minHeight: '0.5vmax',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: themeParam.palette.primary[700],
            outline: themeParam.palette.primary[700],
            borderRadius: SCROLLBAR_RADIUS,
            border: `2px solid transparent`,
            backgroundClip: 'content-box',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            border: `1px solid transparent`,
          },
        }),
      },
    },
    typography: typographyDark(fontFamilies),
    palette: paletteDark,
  });
  theme.shadows = customizeLastShadow(theme.shadows);
  return responsiveFontSizes(theme);
};

// to keep all the static imports working for now.
// this will be removed in the future.
export default themeFormbio;

//height taken by the top toolbar, the breadcrumb and the navigation tabs
export const toolbarStyle = {
  height: {
    normal: 80,
    dense: 48,
  },
};
