import {
  CreateCaasFlowRunParams,
  CreateCaasFromPathParams,
  CreateCaasFromVectorIdParams,
  FlowRun,
  FlowRunPatParams,
  FlowRunType,
  isCaasFromVectorIdParams,
  isCaasFromVectorPath,
} from '../../types';
import { api } from '../../utils/api';

export async function fetchAll(
  oid: string,
  pid: string,
  // TODO add more types for future flow runs
  type: FlowRunType,
): Promise<FlowRun[]> {
  return api
    .get<FlowRun[]>(`/orgs/${oid}/${pid}/flow-runs?type=${type}`)
    .then(({ data }) => data);
}

export function fetchOne({
  id,
  oid,
  pid,
}: {
  id: string;
  oid: string;
  pid: string;
}) {
  return api
    .get<FlowRun>(`/orgs/${oid}/${pid}/flow-run/${id}`)
    .then(({ data }) => data);
}

export function createCaasFlowRun(
  data:
    | CreateCaasFlowRunParams
    | CreateCaasFromVectorIdParams
    | CreateCaasFromPathParams,
) {
  const body = new FormData();
  const { orgId, pid } = data;

  if (isCaasFromVectorIdParams(data)) {
    body.append('vectorId', data.vectorId);
  } else {
    const { vectorName, indication, vectorType, gene } = data;

    indication && body.append('indication', indication);
    body.append('vectorType', vectorType);
    gene && body.append('gene', gene);
    body.append('vectorName', vectorName);

    // Append proper file source
    if (isCaasFromVectorPath(data)) {
      body.append('vaultFile', data.vaultFile);
    } else {
      body.append('vectorFile', data.vectorFile);
    }
  }

  body.append('analysisName', data.analysisName);

  return api.post<{ id: string }>(`/orgs/${orgId}/${pid}/flow-run`, body, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function flowRunVectorInsights({
  oid,
  pid,
  flowRunId,
}: {
  oid: string;
  pid: string;
  flowRunId: string;
}) {
  return api
    .post<{ json: string }>(
      `/orgs/${oid}/${pid}/flow-run/${flowRunId}/vector-insights`,
    )
    .then(({ data }) => data);
}
export function flowRunPat({
  orgId,
  pid,
  configPath,
  vectorVersionId,
}: FlowRunPatParams & { orgId: string; pid: string }) {
  return api
    .post<{ json: string }>(`/orgs/${orgId}/${pid}/flow-run/pat`, {
      configPath,
      vectorVersionId,
    })
    .then(({ data }) => data);
}
