export enum RunStatusValues {
  ALL = 'all',
  RUNNING = 'running',
  COMPLETED = 'completed',
  FAILED = 'failed',
  CANCELED = 'canceled',
  COMPLETED_WARNING = 'completed with warning',
  PENDING = 'pending',
  REMOVED = 'removed',
}
