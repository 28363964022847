import { ProcessExecution, ProgramType } from '@formbio/api';
import { goi1Vectors, goi2Vectors } from './vectors';

const goi1Program: ProcessExecution = {
  id: 'goi1',
  goi: 'goi1',
  title: 'XYL-201 CSY1 (ChronoSnythase-1) for Xylofibromatosis (XYF)',
  description:
    'This Characterization Journey aims to guide your team through the steps required to characterize your drug product from synthesis to safety, purity, identity, and potency on the path to FDA approval.',
  vectors: goi1Vectors,
  type: ProgramType.Neuromuscular,
};

const goi2Program: ProcessExecution = {
  id: 'goi2',
  goi: 'goi2',
  title: 'XYL-201 CTX23 (Chromotropin-X23) for Xylofibromatosis (XYF)',
  description:
    'This Characterization Journey aims to guide your team through the steps required to characterize your drug product from synthesis to safety, purity, identity, and potency on the path to FDA approval.',
  vectors: goi2Vectors,
  type: ProgramType.Neuromuscular,
};
const goi3Program: ProcessExecution = {
  id: 'goi3',
  goi: 'goi3',
  title: 'XYL-301 NFA9 (Neurofluxin-Alpha9) for CardioSpectra Syndrome (CSS)',
  description:
    'This Characterization Journey aims to guide your team through the steps required to characterize your drug product from synthesis to safety, purity, identity, and potency on the path to FDA approval.',
  vectors: goi1Vectors,
  type: ProgramType.Cardiac,
};

const goi4Program: ProcessExecution = {
  id: 'goi4',
  goi: 'goi4',
  title: 'XYL-301 SCB42 (Synthocytin-Beta42) for CardioSpectra Syndrome (CSS)',
  description:
    'This Characterization Journey aims to guide your team through the steps required to characterize your drug product from synthesis to safety, purity, identity, and potency on the path to FDA approval.',
  vectors: goi2Vectors,
  type: ProgramType.Cardiac,
};
const goi5Program: ProcessExecution = {
  id: 'goi5',
  goi: 'goi5',
  title: 'XYL-301 EG18 (Epigenyxin-Gamma18) for CardioSpectra Syndrome (CSS)',
  description:
    'This Characterization Journey aims to guide your team through the steps required to characterize your drug product from synthesis to safety, purity, identity, and potency on the path to FDA approval.',
  vectors: goi1Vectors,
  type: ProgramType.Cardiac,
};

const goi6Program: ProcessExecution = {
  id: 'goi6',
  goi: 'goi6',
  title: 'XYL-301 LD3 (Luminotrin-Delta3) for CardioSpectra Syndrome (CSS)',
  description:
    'This Characterization Journey aims to guide your team through the steps required to characterize your drug product from synthesis to safety, purity, identity, and potency on the path to FDA approval.',
  vectors: goi2Vectors,
  type: ProgramType.Cardiac,
};

export const allProcessExecutions: ProcessExecution[] = [
  goi1Program,
  goi2Program,
  goi3Program,
  goi4Program,
  goi5Program,
  goi6Program,
];
